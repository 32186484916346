import { createAction, props } from '@ngrx/store';
import { CustomerPayorsFilterModel } from 'app/models/api/customer-payors-filter-model';
import { CustomerPayorsReviewFilterModel } from 'app/models/api/customer-payors-review-filter-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { CustomerPayorSearchModel } from 'app/models/api/customer-payor-search-model';
import { PayorSearchModel } from 'app/models/api/payor-search-model';
import { ReviewPayorsModel } from 'app/models/api/review-payors-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum PayorsActions {
	LoadPayors = '[Payors] PayorsLoad',
	PayorsLoaded = '[Payors] PayorsLoaded',

	LoadCustomerPayors = '[Payors] CustomerLoad',
	NotReviewedCustomerPayorsLoaded = '[Payor] NotReviewedCustomerPayorsLoaded',
	ReviewedCustomerPayorsLoaded = '[Payor] ReviewedCustomerPayorsLoaded',

	ReviewCustomerPayors = '[Payors] ReviewPayors',
	CustomerPayorsReviewed = '[Payors] ReviewPayorsLoaded',
	CustomerPayorsExcluded = '[Payors] CustomerPayorsExcluded',

	ReviewCustomerFilteredPayors = '[Payors] ReviewCustomerFilteredPayors',
	CustomerFilteredPayorsReviewed = '[Payors] CustomerFilteredPayorsReviewed',
	CustomerFilteredPayorsExcluded = '[Payors] CustomerFilteredPayorsExcluded',

	ErrorOccurred = '[Payors] ErrorOccurred'
}

export const loadPayors = createAction(
	PayorsActions.LoadPayors,
	props<ActionRequestPayload<SearchRequest>>()
);

export const payorsLoaded = createAction(
	PayorsActions.PayorsLoaded,
	props<ActionResponsePayload<SearchResponse<PayorSearchModel>>>()
);

export const loadCustomerPayors = createAction(
	PayorsActions.LoadCustomerPayors,
	props<
		ActionRequestPayload<FilterSearchRequest<CustomerPayorsFilterModel>>
	>()
);

export const notReviewedCustomerPayorsLoaded = createAction(
	PayorsActions.NotReviewedCustomerPayorsLoaded,
	props<ActionRequestPayload<SearchResponse<CustomerPayorSearchModel>>>()
);

export const reviewedCustomerPayorsLoaded = createAction(
	PayorsActions.ReviewedCustomerPayorsLoaded,
	props<ActionRequestPayload<SearchResponse<CustomerPayorSearchModel>>>()
);

export const reviewCustomerPayors = createAction(
	PayorsActions.ReviewCustomerPayors,
	props<ActionRequestPayload<ReviewPayorsModel>>()
);

export const customerPayorsReviewed = createAction(
	PayorsActions.CustomerPayorsReviewed
);

export const customerPayorsExcluded = createAction(
	PayorsActions.CustomerPayorsExcluded
);

export const reviewCustomerFilteredPayors = createAction(
	PayorsActions.ReviewCustomerFilteredPayors,
	props<
		ActionRequestPayload<
			FilterSearchRequest<CustomerPayorsReviewFilterModel>
		>
	>()
);

export const customerFilteredPayorsReviewed = createAction(
	PayorsActions.CustomerFilteredPayorsReviewed
);

export const customerFilteredPayorsExcluded = createAction(
	PayorsActions.CustomerFilteredPayorsExcluded
);

export const errorOccurred = createAction(PayorsActions.ErrorOccurred);
