import { createAction, props } from '@ngrx/store';
import { ListOptionDataModel } from 'app/models/api/list-option-data-model';
import { SearchResponse } from 'app/models/api/search-response';
import { SubmissionPeriodModel } from 'app/models/api/submission-period-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { CreateSubmissionPeriodModel } from 'app/models/api/create-submission-period-model';
import { UpdateSubmissionPeriodModel } from 'app/models/api/update-submission-period-model';
import { UpdateSubmissionPeriodStatusModel } from 'app/models/api/update-submission-period-status-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { SubmissionPeriodFilterModel } from 'app/models/api/submission-period-filter-model';
import { SubmissionPeriodOptionsFilter } from 'app/models/api/submission-period-options-filter';

import { ActionResponsePayload } from '../action-response-payload';
import { ActionRequestPayload } from '../action-request-payload';

export enum SubmissionPeriodActions {
	LoadSubmissionPeriods = '[SubmissionPeriod] LoadSubmissionPeriods',
	SubmissionPeriodsLoaded = '[SubmissionPeriod] SubmissionPeriodsLoaded',

	PreLoadSubmissionPeriodById = '[SubmissionPeriod] PreLoadSubmissionPeriodById',
	PreLoadSubmissionPeriodByIdLoaded = '[SubmissionPeriod] PreLoadSubmissionPeriodByIdLoaded',

	LoadSubmissionPeriodById = '[SubmissionPeriod] LoadSubmissionPeriodById',
	SubmissionPeriodByIdLoaded = '[SubmissionPeriod] SubmissionPeriodByIdLoaded',

	LoadActiveSubmissionPeriod = '[SubmissionPeriod] LoadActiveSubmissionPeriod',
	ActiveSubmissionPeriodLoaded = '[SubmissionPeriod] ActiveSubmissionPeriodLoaded',

	DeleteSubmissionPeriod = '[SubmissionPeriod] DeleteSubmissionPeriod',
	SubmissionPeriodDeleted = '[SubmissionPeriod] SubmissionPeriodDeleted',

	CreateSubmissionPeriod = '[SubmissionPeriod] CreateSubmissionPeriod ',
	SubmissionPeriodCreated = '[SubmissionPeriod] SubmissionPeriodCreated',

	UpdateSubmissionPeriod = '[SubmissionPeriod] UpdateSubmissionPeriod',
	SubmissionPeriodUpdated = '[SubmissionPeriod] SubmissionPeriodUpdated',

	LoadSubmissionPeriodAsListOptions = '[SubmissionPeriod] LoadSubmissionPeriodAsListOptions',
	SubmissionPeriodAsListOptionsLoaded = '[SubmissionPeriod] SubmissionPeriodAsListOptionsLoaded',

	UpdateSubmissionPeriodStatus = '[SubmissionPeriod] UpdateSubmissionPeriodStatus',
	SubmissionPeriodStatusUpdated = '[SubmissionPeriod] SubmissionPeriodStatusUpdated',

	LoadRecentSubmissionPeriods = '[SubmissionPeriod] LoadRecentSubmissionPeriods',
	RecentSubmissionPeriodsLoaded = '[SubmissionPeriod] RecentSubmissionPeriodsLoaded',

	LoadSubmissionPeriodByIdNotFound = '[SubmissionPeriod] LoadSubmissionPeriodByIdNotFound',
	ErrorOccurred = '[SubmissionPeriod] Error Occurred'
}

//Submission Period list
export const loadSubmissionPeriods = createAction(
	SubmissionPeriodActions.LoadSubmissionPeriods,
	props<
		ActionRequestPayload<FilterSearchRequest<SubmissionPeriodFilterModel>>
	>()
);

export const submissionPeriodLoaded = createAction(
	SubmissionPeriodActions.SubmissionPeriodsLoaded,
	props<ActionResponsePayload<SearchResponse<SubmissionPeriodModel>>>()
);

//Load recent submission periods
export const loadRecentSubmissionPeriods = createAction(
	SubmissionPeriodActions.LoadRecentSubmissionPeriods,
	props<ActionRequestPayload<number>>()
);

export const recentSubmissionPeriodsLoaded = createAction(
	SubmissionPeriodActions.RecentSubmissionPeriodsLoaded,
	props<ActionResponsePayload<SubmissionPeriodModel[]>>()
);

//Pre load submission period by Id
export const preloadSubmissionPeriodById = createAction(
	SubmissionPeriodActions.PreLoadSubmissionPeriodById,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const submissionPeriodByIdPreloaded = createAction(
	SubmissionPeriodActions.PreLoadSubmissionPeriodByIdLoaded,
	props<ActionResponsePayload<IPreLoadModel<SubmissionPeriodModel>>>()
);

//Load submission period by id
export const loadSubmissionPeriodById = createAction(
	SubmissionPeriodActions.LoadSubmissionPeriodById,
	props<ActionRequestPayload<number>>()
);

export const submissionPeriodByIdLoaded = createAction(
	SubmissionPeriodActions.SubmissionPeriodByIdLoaded,
	props<ActionResponsePayload<SubmissionPeriodModel>>()
);

//Load active submission period
export const loadActiveSubmissionPeriod = createAction(
	SubmissionPeriodActions.LoadActiveSubmissionPeriod
);

export const activeSubmissionPeriodLoaded = createAction(
	SubmissionPeriodActions.ActiveSubmissionPeriodLoaded,
	props<ActionResponsePayload<SubmissionPeriodModel>>()
);

//Create submission period
export const createSubmissionPeriod = createAction(
	SubmissionPeriodActions.CreateSubmissionPeriod,
	props<ActionRequestPayload<CreateSubmissionPeriodModel>>()
);

export const submissionPeriodCreated = createAction(
	SubmissionPeriodActions.SubmissionPeriodCreated
);

//Update submission period
export const updateSubmissionPeriod = createAction(
	SubmissionPeriodActions.UpdateSubmissionPeriod,
	props<ActionRequestPayload<UpdateSubmissionPeriodModel>>()
);

export const submissionPeriodUpdated = createAction(
	SubmissionPeriodActions.SubmissionPeriodUpdated
);

//delete submission period
export const deleteSubmissionPeriod = createAction(
	SubmissionPeriodActions.DeleteSubmissionPeriod,
	props<ActionRequestPayload<number>>()
);

export const submissionPeriodDeleted = createAction(
	SubmissionPeriodActions.SubmissionPeriodDeleted
);

//submission period options list
export const loadSubmissionPeriodAsListOptions = createAction(
	SubmissionPeriodActions.LoadSubmissionPeriodAsListOptions,
	props<ActionRequestPayload<SubmissionPeriodOptionsFilter>>()
);

export const submissionPeriodAsListOptionsLoaded = createAction(
	SubmissionPeriodActions.SubmissionPeriodAsListOptionsLoaded,
	props<ActionResponsePayload<ListOptionDataModel<number, string>[]>>()
);

//Update Status
export const updateSubmissionPeriodStatus = createAction(
	SubmissionPeriodActions.UpdateSubmissionPeriodStatus,
	props<ActionRequestPayload<UpdateSubmissionPeriodStatusModel>>()
);

export const submissionPeriodStatusUpdated = createAction(
	SubmissionPeriodActions.SubmissionPeriodStatusUpdated
);

//common
export const loadSubmissionPeriodByIdNotFound = createAction(
	SubmissionPeriodActions.LoadSubmissionPeriodByIdNotFound
);

export const errorOccured = createAction(SubmissionPeriodActions.ErrorOccurred);
