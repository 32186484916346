export enum GeneratedFileTypeModel {
	CustomerLocations = 1,
	FormularyNdc = 2,
	RebateAggregatorNdcRates = 3,
	RebateInvalidRecords = 4,
	RebateFileDuplicates = 5,
	RebateDuplicates = 6,
	RebatesHistory = 7,
	NotReviewedLocations = 8,
	Locations = 9
}
